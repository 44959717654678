import React from "react";
import "./App.css";
import Root from "./Root";
import VideoPlayer from "./VideoPlayer";
import AuthMeeting from "./AuthMeeting";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import CommentList from "./CommentList";
import ErrorPage from "./ErrorPage";

class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/" exact component={ErrorPage}/>
            <Route path="/start" component={Root}/>
            <Route path="/join/:meetingId/:userId" component={AuthMeeting}/>
            {/* <Route path="/live-failed/:meetingId" component={Player} /> */}
            <Route path="/webinar/:meetingId" component={VideoPlayer}/>
            <Route path="/comments/:meetingId" component={CommentList}/>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
