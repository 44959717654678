import React, { Component } from "react";
import {
  MDBInput,
  MDBNotification,
  MDBListGroup,
  MDBListGroupItem,
  MDBContainer,
} from "mdbreact";
import axios from "axios";
// import io from "socket.io-client";
// import queryString from "query-string";
import "./chat.css";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
      showMessage: false,
      meetingId: props.meetingId,
      name: localStorage.getItem("userId"),
      isName: true,
      showErrorMessage: false,
      comments: [],
    };
    this.sendMessage = this.sendMessage.bind(this);
    this.sendMessageOnEnter = this.sendMessageOnEnter.bind(this);
    // this.socket = io("ENDPOINT");
  }

  componentDidMount() {
    // this.getCommentsByMeetingId();
  }

  update(name, e) {
    this.setState({ [name]: e.target.value });
  }

  sendMessageOnEnter(e) {
    if (e.key === "Enter") {
      this.sendMessage();
    }
  }
  async sendMessage() {
    // TODO
    const { message, name, meetingId } = this.state;
    if (message.length <= 0 || name.length <= 0) {
      this.setState({
        showErrorMessage: true,
      });
      setTimeout(() => {
        this.setState({
          showErrorMessage: false,
        });
      }, 3000);
      return;
    }

    const host = `/api/chat/${meetingId}`;

    const data = await axios
      .post(
        host,
        {
          message: message,
          meetingId: meetingId,
          user: name,
          sentTime: new Date(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: `${localStorage.getItem("saltKey")}`,
          },
        }
      )
      .catch((e) => {
        // catch error
      });

    this.setState({
      comments: [message, ...this.state.comments],
    });

    console.log(this.state.message, this.state.meetingId);
    this.setState({
      message: "",
      showMessage: true,
    });

    /*  this.socket.emit("join", { name, meetingId }, (error) => {
      if (error) {
        alert(error);
      }
    }); */

    setTimeout(() => {
      this.setState({
        showMessage: false,
      });
    }, 3000);
    this.setState({
      isName: true,
    });
  }
  render() {
    const { showMessage, showErrorMessage } = this.state;
    return (
      <>
        <MDBContainer>
          <MDBListGroup style={{ width: "22rem" }}>
            {this.state.comments.map((comment, index) => {
              const dateTime = new Date();
              return (
                <>
                  <MDBListGroupItem href="#">
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">{this.state.name}</h5>
                      <small>{dateTime.getMinutes()}Mins</small>
                    </div>
                    <p className="mb-2">{comment}</p>
                  </MDBListGroupItem>
                </>
              );
            })}
          </MDBListGroup>
        </MDBContainer>
        {/* <textarea>{this.props.meetingId}</textarea> */}
        <MDBInput
          label="Name"
          group
          type="text"
          validate
          disabled={this.state.isName}
          value={this.state.name}
          onChange={(event) => this.update("name", event)}
        />
        <MDBInput
          label="Enter Comment"
          group
          type="text"
          validate
          value={this.state.message}
          onKeyDown={this.sendMessageOnEnter}
          onChange={(event) => this.update("message", event)}
        />
        <button
          className="btn-default btn Ripple-parent right"
          onClick={this.sendMessage}
        >
          Send
          <div data-test="waves" className="Ripple is-reppling rp"></div>
        </button>
        {showMessage && (
          <MDBNotification
            show
            fade
            iconClassName="text-success"
            title="Message Sent Successfully.."
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              zIndex: 9999,
            }}
          />
        )}

        {showErrorMessage && (
          <MDBNotification
            show
            fade
            iconClassName="text-danger"
            title="Error Invalid Entry"
            style={{
              position: "fixed",
              top: "10px",
              right: "10px",
              zIndex: 9999,
            }}
          />
        )}
      </>
    );
  }
}
