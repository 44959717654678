import React, { Component } from "react";

export default class AuthMeeting extends Component {
  constructor(props) {
    super(props);

    this.state = {
      meetingId: "",
      userId: "",
    };
  }

  componentDidMount() {
    const { meetingId, userId } = this.props.match.params;
    this.setState({
      meetingId: meetingId,
      userId: userId,
    });

    // validate meeting id and saltKey
    // generate jwt token
    // store jwt token
    this.storeMeetingDetails(meetingId, userId);
    // this.props.history.pushState(`/live2/${meetingId}`);
    // this.history.pushState(`/live2/${meetingId}`);
    window.location.href = `/webinar/${meetingId}`;
  }

  storeMeetingDetails(meetingId, userId) {
    localStorage.setItem("meetingId", meetingId);
    localStorage.setItem("userId", userId);
  }

  render() {
    return <div>Loading.......</div>;
  }
}
