import React, { Component } from "react";
import axios from "axios";
import { MDBListGroup, MDBListGroupItem, MDBContainer } from "mdbreact";

export default class CommentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingId: props.match.params.meetingId,
      messages: [],
    };
    this.getCommentsByMeetingId = this.getCommentsByMeetingId.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.getCommentsByMeetingId();
    }, 10000);
  }

  async getCommentsByMeetingId() {
    // const host = "http://13.235.5.43";
    const response = await axios
      .get(`/api/chat/${this.state.meetingId}`)
      .catch((e) => {
        // catch error
      });

    // TODO
    // console.log(data);
    if (response.data) {
      this.setState({
        messages: response.data,
      });
    }
  }

  render() {
    return (
      <>
        {this.state.meetingId}
        <MDBContainer>
          <MDBListGroup style={{ width: "22rem" }}>
            <MDBListGroupItem active href="#">
              {this.state.messages.map((message, index) => {
                return (
                  <>
                    <div className="d-flex w-100 justify-content-between">
                      <h5 className="mb-1">{message.user}</h5>
                      <small>{message.sentTime}</small>
                    </div>
                    <p className="mb-2">{message.message}</p>
                  </>
                );
              })}
            </MDBListGroupItem>
          </MDBListGroup>
        </MDBContainer>
      </>
    );
  }
}
