import React from "react";
import "mdbreact/dist/css/mdb.css";
import {
  MDBInput,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBBtn,
} from "mdbreact";

const ErrorPage = () => {
  return (
    <MDBContainer>
      <section className="my-5">
        <h1 className="h1-responsive font-weight-bold text-center my-5">
          Contact us
        </h1>
        <p className="text-center w-responsive mx-auto pb-5">
          Scale-up Consultants
        </p>
        <MDBRow>
          <MDBCol lg="5" className="lg-0 mb-4">
            <MDBCard>
              <MDBCardBody>
                <div className="form-header blue accent-1">
                  <h2 className="mt-2">
                    <MDBIcon icon="envelope"/> Write to us:
                  </h2>
                </div>
                <p className="dark-grey-text">
                  We'll write as earliest possible.
                </p>
                <div className="md-form">
                  <MDBInput
                    icon="user"
                    label="Your name"
                    iconClass="grey-text"
                    type="text"
                    id="form-name"
                  />
                </div>
                <div className="md-form">
                  <MDBInput
                    icon="envelope"
                    label="Your email"
                    iconClass="grey-text"
                    type="text"
                    id="form-email"
                  />
                </div>
                <div className="md-form">
                  <MDBInput
                    icon="tag"
                    label="Subject"
                    iconClass="grey-text"
                    type="text"
                    id="form-subject"
                  />
                </div>
                <div className="md-form">
                  <MDBInput
                    icon="pencil-alt"
                    label="Message"
                    iconClass="grey-text"
                    type="textarea"
                    id="form-text"
                  />
                </div>
                <div className="text-center">
                  <MDBBtn color="light-blue">Submit</MDBBtn>
                </div>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
          <MDBCol lg="7">
            <div
              id="map-container"
              className="rounded z-depth-1-half map-container"
              style={{height: "400px"}}
            >
              <iframe
                src="https://maps.google.com/maps?width=100%&amp;height=600&amp;hl=en&amp;coord=17.4986882,78.3899008&amp;q=nizampet+(Octa)&amp;ie=UTF8&amp;t=&amp;z=16&amp;iwloc=B&amp;output=embed"
                title="Octa Streaming Solution"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{border: 0}}
              />
            </div>
            <br/>
            <MDBRow className="text-center">
              <MDBCol md="4">
                <MDBBtn tag="a" floating color="blue" className="accent-1">
                  <MDBIcon icon="map-marker-alt"/>
                </MDBBtn>
                <p>We Hub, Rd #45, Jubilee Hills</p>
                <p className="mb-md-0">Hyderabad, Telangana, India.</p>
              </MDBCol>
              <MDBCol md="4">
                <MDBBtn tag="a" floating color="blue" className="accent-1">
                  <MDBIcon icon="phone"/>
                </MDBBtn>
                <p>+(91) 9885503069</p>
                <p className="mb-md-0">Mon - Fri</p>
              </MDBCol>
              <MDBCol md="4">
                <MDBBtn tag="a" floating color="blue" className="accent-1">
                  <MDBIcon icon="envelope"/>
                </MDBBtn>
                <p>malika@radiusduech.com</p>
                {/* <p className="mb-md-0">contact@octa.work</p> */}
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
      </section>
    </MDBContainer>
  );
}

export default ErrorPage;
